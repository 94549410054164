<template>
  <div>
    <b-overlay
      :show="$apollo.loading"
      rounded="sm"
      spinner-variant="primary"
    >
      <b-card
        class="mt-2"
        style="text-align: left;"
      >
        <div class="custom-search d-flex justify-content-start">
          <b-form-group
            class="form-inline"
            label="Search"
            label-size="sm"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search Startups"
              type="text"
            />
            <b-button
              variant="primary"
              @click="$bvModal.show('addparticipantmodal')"
            >
              <feather-icon
                class="mr-25"
                icon="PlusIcon"
              />
              Add Participant
            </b-button>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength,
            position: 'top',
          }"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Action -->
            <!-- Status field -->
            <span v-if="props.column.field === 'status'">
              <!-- Badge for status -->
              <b-badge
                :variant="props.row.is_active ? 'light-success' : 'light-danger'"
                style="text-transform: capitalize;"
              >
                {{ props.row.is_active ? "Active" : "Inactive" }}
              </b-badge><br>

              <!-- Checkbox for changing status -->
              <b-form-checkbox
                v-b-tooltip="props.row.is_active ? 'Deactivate' : 'Activate'"
                :checked="props.row.is_active"
                class="mt-50 custom-control-primary"
                name="check-button"
                switch
                @change="updateStatus(props.row.id, !props.row.is_active)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </span>

            <!-- Action field -->
            <span v-else-if="props.column.field === 'action'">
              <!-- Button for delete action -->
              <b-button
                size="sm"
                variant="flat-danger"
                class="text-capitalize"
                @click="$bvModal.show('deleteModal'); selectedRow = props.row.id;"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template
            slot="pagination-top"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mb-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','20','30']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of <span class="font-medium-4 ml-25"><b-badge
                  variant="light-primary"
                >{{
                  props.total
                }} Startups
                </b-badge></span></span>

              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  class="mt-50"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      <b-modal
        id="addparticipantmodal"
        size="lg"
        title="Add Participant"
        hide-footer
        no-close-on-backdrop
      >
        <b-row>
          <b-col>
            <b-form-group
              style="margin-top: 0.1rem;"
              label="Select Application Round"
              label-size="sm"
            >
              <treeselect
                v-model="selectedOperation"
                style="margin-top: 0.4rem;"
                :normalizer="normalizer"
                :options="operations"
                :get-option-label="option => option.title"
                :reduce="option => option.id"
                placeholder="Choose application round"
                @select="checkApplication"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              style="margin-top: 0.1rem;"
              label="Select Applicant Startup"
              label-size="sm"
            >
              <treeselect
                v-model="selectedstartup"
                style="margin-top: 0.4rem;"
                :normalizer="normalizer2"
                :options="startupsdata"
                :reduce="option => option.id"
                placeholder="Choose Startup"
                :multiple="true"
              />
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-button
              class="mt-2"
              variant="outline-primary"
              @click="$bvModal.hide('addparticipantmodal'); promoteParticipants()"
            >
              <feather-icon
                class="mr-25"
                icon="PlusIcon"
              />
              Add
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        id="deleteModal"
        ok-title="Yes, Delete"
        ok-variant="danger"
        button-size="sm"
        no-close-on-backdrop
        centered="true"
        size="sm"
        title="Confirm Delete"
        @ok="deleteMentor()"
      >
        <h6>Are you sure you want to delete this mentor?</h6>
      </b-modal>
      <b-modal
        id="assignStartup"
        ok-only
        ok-title="Assign"
        title="Assign Startup"
        no-close-on-backdrop
        @ok="assignStartup"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Select Startups"
              label-for="selectStartups"
            >
              <v-select
                id="selectStartups"
                v-model="startupParticipant"
                :get-option-label="option => option.programs_applicantstable.users_organizationtable.title"
                :options="startups"
                :reduce="option => option.id"
                placeholder="Select Startups"
              />
              <vue-good-table
                :columns="columns2"
                :pagination-options="{
                  enabled: true,
                  perPage:pageLength,
                  position: 'top',
                }"
                :rows="startups2"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm }"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.formattedRow[props.column.field].field === 'action'">
                    <b-button
                      variant="danger"
                      size="sm"
                    >Deactivate
                    </b-button>
                  </span>

                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
                <!-- pagination -->
                <template
                  slot="pagination-top"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mb-1">
                      <span class="text-nowrap ">
                        Showing 1 to
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap"> of <span class="font-medium-4 ml-25"><b-badge
                        variant="light-primary"
                      >{{
                        props.total
                      }} Startups
                      </b-badge></span></span>

                    </div>
                    <div>
                      <b-pagination
                        :per-page="pageLength"
                        :total-rows="props.total"
                        :value="1"
                        align="right"
                        class="mt-50"
                        first-number
                        last-number
                        next-class="next-item"
                        prev-class="prev-item"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
    </b-overlay>
  </div>
</template>
<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BFormCheckbox,
  BRow,
  BModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BFormInput,
    BFormCheckbox,
    BCard,
    BCol,
    BFormGroup,
    BRow,
    BBadge,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BModal,
    Treeselect,
  },
  data() {
    return {
      operations: [],
      mutationLoading: false,
      pageLength: 20,
      options: [],
      mentor: {
        id: null,
        email: null,
        designation: null,
      },
      searchData: null,
      selectedfid: null,
      searchTerm: '',
      selectedstartup: null,
      rows: [],
      startups: [],
      startups2: [],
      startupsdata: [],
      columns: [
        {
          label: 'Startup',
          field(row) {
            return `${row.programs_applicantstable.users_organizationtable.title} - ${row.programs_applicantstable.users_organizationtable.type}`
          },
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        accept: 'light-success',
        invited: 'light-primary',
        reject: 'light-danger',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    existingUser() {
      return this.rows.findIndex(e => e.user_id === this.mentor.id) !== -1
    },
  },
  methods: {
    checkApplication(e) {
      this.startupsdata = e.programs_applicantstables
      // console.log(e)
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    normalizer2(node) {
      return {
        id: node.id,
        label: `${node.users_organizationtable.title} - ${node.users_organizationtable.type}`,
        children: node.programs_operationstables,
      }
    },
    addReview() {
      this.mutationLoading = true
      this.$apollo.mutate(
        {
          mutation: gql`mutation($data: [programs_assignmentquestionstable_insert_input!]!) {
                        insert_programs_assignmenttable_one(object: {title: "${this.reviewTitle}",type: "${this.reviewType}", status: "draft", review_status: "draft", operations_id: ${this.selectedOperation}, programs_assignmentquestionstables: {data: $data}}) {
                          id
                        }
                      }`,
          variables: {
            data: this.applicationForm,
          },
          update: (store, { data: { insert_programs_assignmenttable_one } }) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: insert_programs_assignmenttable_one.id ? 'Review added successfully' : 'Failed to create review',
                icon: insert_programs_assignmenttable_one.id ? 'CheckIcon' : 'XIcon',
                variant: insert_programs_assignmenttable_one.id ? 'success' : 'danger',
              },
            })
            this.$apollo.queries.operations.refetch()
            this.mutationLoading = false
          },
        },
      )
      this.reviewTitle = null
      this.selectedOperation = null
      this.applicationForm = null
      this.reviewType = null
    },
    updateAssignmentStatus(status, item, col) {
      const update = status === 'enabled' ? 'disabled' : 'enabled'
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_assignmenttable_by_pk(pk_columns: {id: ${item}}, _set: {${col}: ${update}}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.operations.refetch()
          this.mutationLoading = false
        },
      })
    },
    fetchUser() {
      if (!this.mentor.email) return false
      this.mutationLoading = true
      this.$apollo.query({
        query: gql`query options($q: String!){
              users_customuser(where: {email: {_ilike: $q}}) {
                      full_name
                      email
                      id
                    }
              }`,
        variables: { q: this.mentor.email },
      })
        .then(({ data }) => {
          this.mutationLoading = false
          this.searchData = !data.users_customuser[0] ? false : data.users_customuser[0].full_name
          this.mentor.id = data.users_customuser[0]?.id || null
        })
      return true
    },
    addMentor() {
      this.$apollo.mutate({
        mutation: gql`
          mutation {
            insert_users_associationtable_one(object: {program_id: ${this.$route.params.id}, role: "${this.mentor.designation}", status: "Invited", is_participant: true, user_id: ${this.mentor.id}}) {
              id
            }
          }`,
        update: () => {
          this.mentor.id = null
          this.mentor.designation = null
          this.$apollo.queries.rows.refetch()
        },
      })
    },
    promoteParticipants() {
      const startupParticipantsData = this.selectedstartup.map(sdata => ({
        applicant_id: sdata,
        program_id: this.$route.params.id,
      }))
      this.mutationLoading = true
      this.$apollo.mutate(
        {
          mutation: gql`mutation insertStartupParticipants($data: [programs_startupparticipants_insert_input!]!, $data1: [Int!]!) {
                        insert_programs_startupparticipants(objects: $data) {
                          affected_rows
                        }
                        update_programs_applicantstable(where: { id: { _in: $data1 } }, _set: { status: "accepted" }) {
                          affected_rows
                        }
                      }`,
          variables: {
            data: startupParticipantsData,
            data1: this.selectedstartup,
          },
          update: (store, { data }) => {
            const insertData = data.insert_programs_startupparticipants
            const updateData = data.update_programs_applicantstable
            this.$toast({
              component: ToastificationContent,
              props: {
                title: insertData.affected_rows > 0 ? 'Participants added successfully' : 'Failed to add participants',
                icon: insertData.affected_rows > 0 ? 'CheckIcon' : 'XIcon',
                variant: insertData.affected_rows > 0 ? 'success' : 'danger',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: updateData.affected_rows > 0 ? 'Status updated successfully' : 'Failed to update status',
                icon: updateData.affected_rows > 0 ? 'CheckIcon' : 'XIcon',
                variant: updateData.affected_rows > 0 ? 'success' : 'danger',
              },
            })
            this.$apollo.queries.operations.refetch()
            this.mutationLoading = false
          },
        },
      )
      this.reviewTitle = null
      this.selectedOperation = null
      this.applicationForm = null
      this.reviewType = null
    },
    getStartups() {
      this.$apollo.queries.startups.refetch()
      this.$apollo.queries.startups2.refetch()
    },
    assignStartup() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_programs_startupassignmenttable_one(object: {participant_id: "${this.startupParticipant}", partner_id: "${this.selectedRow}"}) {
            id
          }
        }`,
        update: (store, { data: { insert_programs_startupassignmenttable_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_startupassignmenttable_one.id ? 'Added successfully' : 'Failed to add',
              icon: insert_programs_startupassignmenttable_one.id ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_startupassignmenttable_one.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.startups.refetch()
          this.$apollo.queries.startups2.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateStatus(item, status) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_startupparticipants_by_pk(pk_columns: {id: ${item}}, _set: {is_active: ${status}}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    // updateStatus(status, item, col) {
    //   const update = status ? 'false' : 'true'
    //   this.mutationLoading = true
    //   this.$apollo.mutate({
    //     mutation: gql`mutation {
    //       update_programs_partnerstable_by_pk(pk_columns: {id: ${item}}, _set: {${col}: ${update}}) {
    //         id
    //       }
    //     }`,
    //     update: () => {
    //       this.$apollo.queries.rows.refetch()
    //       this.mutationLoading = false
    //     },
    //   })
    // },
    deleteMentor() {
      const item = this.selectedRow
      this.mutationLoading = true
      if (item) {
        this.$apollo.mutate({
          mutation: gql`mutation {
            update_programs_startupparticipants_by_pk(pk_columns: {id: ${item}}, _set: {is_deleted: true}) {
              id
            }
          }`,
          update: () => {
            this.$apollo.queries.rows.refetch()
            this.mutationLoading = false
          },
        })
      }
    },
  },
  apollo: {
    operations: {
      query() {
        return gql`{
          programs_applicationformstable(where: {program_id: {_eq: ${this.$route.params.id}} ${this.$route.params.wid ? `, id: {_eq: ${this.$route.params.wid}}` : ''}}, order_by: {id: asc}) {
            id
            title
            programs_applicantstables(where: {programs_startupparticipants_aggregate: {count: {predicate: {_eq: 0}}}}) {
              id
              type
              users_customuser {
                full_name
              }
              users_organizationtable {
                title
                type
              }
            }
          }
        }`
      },
      update: data => data.programs_applicationformstable,
    },
    rows: {
      query() {
        return gql`
        {
          programs_startupparticipants(where: {program_id: {_eq: ${this.$route.params.id}}}) {
            id
            is_active
            programs_applicantstable {
              users_organizationtable {
                title
                type
              }
              users_customuser {
                full_name
              }
            }
          }
        }`
      },
      update: data => data.programs_startupparticipants,
    },
    startups: {
      query() {
        return gql`{
          programs_startupparticipants(where: {programs_applicantstable: {program_id: {_eq: ${this.$route.params.id}}}, is_active: {_eq: true}, programs_startupassignmenttables_aggregate: {count: {predicate: {_eq: 0}, filter: {partner_id: {_eq: ${this.selectedRow}}}}}}) {
            id
            programs_applicantstable {
              users_organizationtable {
                title
              }
            }
          }
        }`
      },
      update: data => data.programs_startupparticipants,
    },
    startups2: {
      query() {
        return gql`{
          programs_startupassignmenttable(where: {partner_id: {_eq: ${this.selectedRow}}}) {
            participant_id
            partner_id
            programs_startupparticipant {
              programs_applicantstable {
                users_organizationtable {
                  title
                }
              }
            }
          }
        }`
      },
      update: data => data.programs_startupassignmenttable,
    },
  },
}
</script>
